import React, {useState,useEffect} from 'react'
import './NavBar.css'
import {Link,useNavigate} from "react-router-dom";
import { History } from './History';
export const NavBar = () =>{
    const {pathname} = window.location;
  
    return(
        <div className={`navBar`}>
            <History/>
            <div>
            <Link to="/" className={pathname==='/'?'active':""}>Home</Link>
            <i>Coming Soon - Beta Version 1.1.0</i>
            <Link to="/about" className={pathname==='/about'?'active':""}>About</Link>
            <Link to="/help" className={pathname==='/help'?'active':""}>Help</Link>
            </div>
            
        </div>
    )
}