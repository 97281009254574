import {useState,useEffect} from 'react'
import { BrowserRouter, Routes, Route} from "react-router-dom";
import { Home } from "./pages/Home";
import {About} from './pages/About'
import { Help } from './pages/Help';
import './App.css';

function App() {

  const [mapObj,setMapObj] = useState("")
 
  return (
   <BrowserRouter>
    <Routes>
      <Route index element={<Home mapObj={mapObj} setMap={setMapObj}/>}/>
      <Route path='/about' element={<About/>}/>
      <Route path='/help' element={<Help/>}/>

    </Routes>
   </BrowserRouter>
  );
}

export default App;
