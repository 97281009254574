import React, {useState,useEffect} from 'react'
import './style.css'
import {Link,useNavigate} from "react-router-dom";
import { HeaderBar } from '../components/HeaderBar/HeaderBar';
import { NavBar } from '../components/NavBar/NavBar';

export const About = (props) =>{
    

    return(
        <div className="bodyContainer">
          <HeaderBar/>
          About
          <NavBar/>
          
        </div>
    )
}