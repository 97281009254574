import React, {useState,useEffect} from 'react'
import './History.css'
import {Link,useNavigate} from "react-router-dom";

export const History = () =>{


    const historyItems = [
        {
            title: "Links removed for Stable and Beta Versionin prep for Beta 1.1.0 Launch",
            desc:"No date has been set for a wider launch of Beta 1.1.0 - but development is close enough to completion that the links here have been frozen.",
            date:"11 August 2024"
        },
        {
            title: "Beta Version 1.1.4 release",
            desc:"Minor bug fixes regarding map launches and styling issues with the My Repo dropwdown menu.",
            date:"6 August 2024"
        },
        {
            title: "Beta Version 1.1.3 release",
            desc:"Added My Repos to save the repos you use.",
            date:"6 August 2024"
        },
        {
        title: "Beta Version 1.1.0 release",
        desc:"This beta version includes a splashscreen on loading to show the version the user is loading.",
        date:"29 June 2024"
    },
    {
        title:"Front facing website launched!",
        desc:"This space is for launching the current versions of the app and for learning and testimonials.",
        date: "24 June 2024"
    }]

    const historyElements = historyItems.map((item,i)=>{
        return(
         
  
            <div className="historyElement">
                <div className="historyTitle">{item.title}</div>
                <div className="historyDate">{item.date}</div>
                <div className="historyDesc">{item.desc}</div>
            </div>
           
            
        )
    })

    return(
        <div className='History'>
                      <div className="historyMainTitle">Latest Updates</div>
           <div>
           {historyElements}
            </div>
        </div>
    )
}