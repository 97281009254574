import title1 from '../../assets/graphics/title1.jpg'
import title2 from '../../assets/graphics/title2.jpg'
import title3 from '../../assets/graphics/title3.jpg'
import logoWhite from '../../logowhite.svg'
import './style.css'
import React, {useState,useEffect} from 'react'
export const HeaderBar = () =>{
    let hasLoaded = false
    useEffect(()=>{
    if (!hasLoaded){
        hasLoaded = true;
        const max = 2
        let index = 1;
        setInterval(()=>{
            if (index===0){
                document.getElementById(`title${max}`).className = 'headerImage hidden'
                document.getElementById(`title${index}`).className = 'headerImage'
                index = index+1
            }else{
                document.getElementById(`title${index-1}`).className = 'headerImage hidden'
                document.getElementById(`title${index}`).className = 'headerImage'
                if (index===max){
                    index = 0
                }else{
                    index = index + 1
                }
            }

            
        },10000)
    }
    },[])
    return (
        <>
        <div className="homeHeaderImageContainer">
                    <img src={title1} id="title0" className="headerImage " alt="title1"/>
                    <img src={title2} id="title1" className="headerImage hidden" alt="title2"/>
                    <img src={title3} id="title2" className="headerImage hidden" alt="title3"/>
                </div>
                <div className="homeHeaderLogo">
                     <img src={logoWhite} id='logoWhite' alt='logo'/>
                        <div className="headerTitle">
                    <div>KarstGIS</div>
                    <div>Software for Karst Mapping</div>
                        </div>
                </div>
        </>
    )
}